<template>
  <div class="index-page">
    <div class="top-main flex-center-between">
      <div>
        <div class="title">Hello <span style="font-size: 0.6rem">{{username}}</span></div>
        <div class="desc">{{appName}}{{i18n.t('index.title')}}</div>
      </div>
      <div class="header" :class="`header-${avatar}`" @click="toPath('header')"></div>
    </div>

    <div class="account-box">
      <div class="flex-center-between account">
        <div>
          <div class="label">{{i18n.t('index.label1')}}($)</div>
          <div class="count">${{parseFloat(userMoney).toFixed(2)}}</div>
        </div>
        <div class="flex-column-center">
          <Button class="red" @click="toPath('recharge')">{{i18n.t('index.label2')}}</Button>
          <Button class="green" @click="toPath('withdraw')">{{i18n.t('index.label3')}}</Button>
        </div>
      </div>

      <div class="flex tab-list">
        <div class="tab flex-column-center">
          <div class="count">{{parseFloat(commissionInfo.YesterdayCommission || 0).toFixed(2)}}</div>
          <div class="label">{{i18n.t('index.label4')}}($)</div>
        </div>
        <div class="tab flex-column-center">
          <div class="count">{{parseFloat(commissionInfo.Commission || 0).toFixed(2)}}</div>
          <div class="label">{{i18n.t('index.label5')}}($)</div>
        </div>
        <div class="tab flex-column-center">
          <div class="count">{{parseFloat(commissionInfo.TodayCommission || 0).toFixed(2)}}</div>
          <div class="label">{{i18n.t('index.label6')}}($)</div>
        </div>
        <div class="tab flex-column-center" v-if="show_freeze == 1">
          <div class="count">{{parseFloat(moneyFee).toFixed(2)}}</div>
          <div class="label">{{i18n.t('index.label7')}}($)</div>
        </div>
      </div>
    </div>

    <div class="menu-list flex-start-between">
      <div class="list flex-column-center"  v-for="(item, i) in menuList" :key="i" @click="toPath(item.path)">
        <div class="img-bg" :class="`bg-${i + 1}`">
          <img :src="item.icon" alt="">
        </div>
        <span class="label">{{item.name}}</span>
      </div>
    </div>

    <div class="title">{{i18n.t('index.label8')}}</div>
    <div class="task-box flex-center-between" @click="toPath('task')">
      <div>
        <div class="name">{{appName}}</div>
        <div>
          <Button>{{i18n.t('index.label9')}}</Button>
        </div>
      </div>
      <div>
        <img :src="goodsUrl"  alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {Button, Dialog} from 'vant'
import { userInfo } from '@/api/user'
import { getCommissionInfo } from '@/api/index'
export default {
  name: 'about',
  components: {
    Button
  },
  data() {
    return {
      activeIndex: 0,
      userMoney: '0.00',
      moneyFee: 0,
      username: "",
      commissionInfo: {},
      goodsUrl: "",
      goodsUrl2: require('../../assets/imgs/goods.webp'),
      timer: null,
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    logo() {
      return this.$store.state.logo
    },
    show_freeze() {
        return this.$store.state.show_freeze
    },
    avatar() {
      return this.$store.state.avatar
    },
    asset_positive(){
      return this.$store.state.asset_positive
    },
    menuList() {
      return [
        {
          icon: require('../../assets/imgs/licai.svg'),
          name: this.i18n.t('index.label10'),
          path: ''
        },
        {
          icon: require('../../assets/imgs/recharge.svg'),
          name: this.i18n.t('index.label11'),
          path: 'recharge'
        },
        {
          icon: require('../../assets/imgs/withdraw.svg'),
          name: this.i18n.t('index.label12'),
          path: 'withdraw'
        },
        {
          icon: require('../../assets/imgs/share.svg'),
          name: this.i18n.t('index.label13'),
          path: 'share'
        },
      ]
    }
  },
  mounted() {
    this.openWindow()
    if(this.$store.state.logo.length < 14) {
      this.goodsUrl = this.goodsUrl2
    } else {
      this.goodsUrl = this.logo
    }
    this.init()
    this.startTimer()
  },
  beforeRouteLeave(to, from, next) {
    this.destroyTimer();
    next();
  },
  methods: {
    openWindow() {
      let pop_window = sessionStorage.getItem('pop_window')
      let that = this
      if(this.$store.state.pop_window === "true" && pop_window !== "true") {
        let text = this.i18n.t("index.desc1")
        let title = this.i18n.t("index.desc_title")
        let ensure = this.i18n.t("register.btn1")
        // Inshallah|Shop
        // 使用 replace() 方法来替换文本
        // appName
        title = title.replaceAll("$1", this.$store.state.appName);
        text = text.replaceAll("$1", this.$store.state.appName);
        text = text.replaceAll("$2", "\n");
        Dialog.alert({
          // title: title,
          message: text,
          messageAlign: 'left',
          confirmButtonText: ensure,
        }).then(() => {
          sessionStorage.setItem('pop_window', 'true')
          // 记录sessionStorage
          // on close
        });
      }
    },
    handleImageError() {
      this.goodsUrl = this.goodsUrl2; // 图片加载错误时，将显示指定的错误图片
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.init()
      }, 3000);
    },
    destroyTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    init() {
      userInfo().then(res => {
        this.$store.state.avatar = res.data.Avatar || 91
        this.userMoney = res.data.UserAccount.Balance - res.data.UserAccount.Freeze
        if(this.$store.state.asset_positive === true && this.userMoney < 0) {
          this.userMoney = res.data.UserAccount.Total
        }
        this.moneyFee =  res.data.UserAccount.Freeze
        this.username =  res.data.UserName
      })

      getCommissionInfo().then(res => {
        this.commissionInfo = res.data
      })
      
    },
   
  },
}
</script>
<style lang="less">

</style>
